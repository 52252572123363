.root {
  margin-top: 74px;
}

.wrapper {
  display: flex;
  margin-top: 20px;
  column-gap: 12px;
  align-items: stretch;
  max-height: 291px;
}
