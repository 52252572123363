@import "@root/theme/colors.scss";

.root {
}

.header {
  padding-bottom: 1rem;
}

.filterMenu,
.metricsMenu,
.sortByMenu,
.groupByMenu {
  position: absolute;
  top: 120%;
  border: 1px solid $primaryDarkColor;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 4px 15px 0 rgba(32, 32, 32, 0.1);
  background-color: $white100Color;
  z-index: 2;
}

.select {
  min-width: 200px;
}

.selectInput {
  margin-bottom: 10px;
}

.filterButton {
  position: relative;
}
