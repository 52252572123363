@import "@root/theme/colors.scss";
.sssoButton {
  width: 100%;
  border-color: $secondary100Color !important;
  background: transparent;
}

.iconContainer {
  width: 24px;
  height: 24px;
}
