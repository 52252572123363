@import "@root/theme/utils.scss";

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  box-sizing: border-box;
  border: $p01 solid $primary10Color;
  border-radius: $p08;
  gap: $p12;
}

.title {
  width: 180px;
}

.content {
  & > * {
    flex: 1 1 100%;
  }
}

.metric {
  justify-content: flex-start;
}

.root.inline {
  flex-direction: row;
  align-items: center;
}

.goalValue {
  font-weight: 600;
}

.statisticMetric {
  box-sizing: border-box;
  background-color: $primary05Color;
  border-radius: 0.75rem;
  padding: 0.5rem;
  height: 100%;

  .valueContainer {
    padding-top: 8px;
  }
}
