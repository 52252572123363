.grid {
  display: grid;
  column-gap: 2rem;
  width: 100%;
}

.tableContainer {
  width: 522px;
  max-height: 19.5rem;
  overflow-y: auto;
}
