.root {
  flex-grow: 1 !important;
  height: 100%;
  background-color: #fff;
  border-radius: 1.5rem;
  padding: 20px;
  box-shadow: 0px 4px 20px rgba(26, 115, 169, 0.1);
}
.colorMark {
  width: 16px;
  aspect-ratio: 1/1;
  border-radius: 100%;
}

.legendWrapper {
  margin: 20px 0;
  & > * {
    flex: unset;
  }
}

.infoIcon {
  font-size: 1rem !important;
}

.chartWrapper {
  height: 290px;
}
