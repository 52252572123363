.root {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(26, 115, 169, 0.1);
  border-radius: 24px;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.headerText {
  text-align: start !important;
}
.wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  text-align: left;
}

.section {
  display: flex;
}

.leftSection {
  margin-right: 30px;
}

.rightSection {
  margin-left: 30px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.noData {
  padding: 31px 0;
}
