@import "@root/theme/colors.scss";
@import "@root/theme/size.scss";

.defaultPaddings {
  padding: 8px 15px;
  width: 100%;
}
.sortArrow {
  margin-left: 15px;
}

.defaultText {
  margin: 9px 0;
}

.metric {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  padding: 8px 15px;
  min-width: 100%;
}
.metricCell {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  height: 100%;
  min-width: 100%;
  position: relative;
  &:hover {
    .metricCellPopupHelper {
      display: block;
    }
  }
}

.metricCellPopupHelper {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 20;
  background: $black80Color;
  border-radius: 4px;
  padding: 5px 10px;
}

.labelCell {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  padding: 4px 12px;
}

.previewImageContainer {
  width: 60px;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
  background-image: url("/assets/images/image-placeholder.svg");
  background-color: $black20Color;
  background-size: 100% 100%;
  .preview {
    height: 100%;
    width: 100%;
  }
}
