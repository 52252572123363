@import "@root/theme/colors.scss";

.root {
  position: relative;
  width: 100%;
  min-width: 280px;
  height: 39px;
  font-family: Poppins;
  outline: none;
  cursor: pointer;
}

.input::-webkit-calendar-picker-indicator {
  display: none;
}

.input[type="date"]::-webkit-input-placeholder {
  visibility: hidden !important;
}

.input {
  color: $grayTextColor;
  font-family: Poppins;
  outline: none;
  font-size: 14px;
  line-height: 21px;
  box-sizing: border-box;
  width: 100%;
  padding: 7px 45px 7px 14px;
  border: 1px solid $secondaryDarkColor;
  border-radius: 10px;
  background-color: $lightTextColor;
}

.input:disabled {
  color: $grayTextColor;
}

.input.invalid {
  border-color: $redTextColor;
  color: $redTextColor;
}

.wrapper {
  z-index: 0;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
  background-color: transparent;
}

.arrow {
  right: 0;
  top: 0;
  padding: 10px 15px;
  position: absolute;
  color: $primaryDarkColor;
  pointer-events: all;
}

.active {
  .input {
    border: none;
    border-radius: 10px 10px 0 0;
    border-color: $lightTextColor;
  }
  & > .wrapper {
    z-index: 2;
    background-color: $lightTextColor;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }
  .arrow {
    color: $primaryLightColor;
  }
}

.verticalDivider {
  flex-grow: 1;
  height: 15px;
  width: 1px;
  background-color: $black30Color;
}

.calendar {
  min-width: 260px;
  padding: 8px;
}

.datePickerContainer {
  padding: 8px 12px;
  border: 1px solid $black30Color;
  border-radius: 8px;
}

.iconStyles {
  color: $primary100Color;
}

.selectBoxContainer {
  border-radius: 8px;
  border: 1px solid $black30Color;

  .item {
    width: 106px;
    padding-block: unset;
    padding: 13px 13px;
    border: none;
    border-radius: 0;
    cursor: pointer;

    &:hover {
      background: $primary05Color;
    }

    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
