@import "@root/theme/colors.scss";

.root {
  height: 100%;
  width: 100vw;
  padding: 7.5rem;
  box-sizing: border-box;
  flex: 1 1;

  & > * {
    max-width: 50%;
    flex-shrink: 0;
    min-width: 41.875rem;
    width: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 14.8rem 6rem;
  gap: 16px;
  border-radius: 2rem;
  box-sizing: border-box;
  background: linear-gradient(
    180deg,
    $primaryLightColor 0%,
    $primaryDarkColor 100%
  );
}

.logo {
  position: fixed;
  left: 3.75rem;
  top: 1.75rem;
}

.list {
  padding: 0;
  margin-block: 8px;
  li {
    display: flex;
    font-size: 16px;
    line-height: 24px;
    margin-block: 12px;
    color: $white100Color;
  }
  li > i {
    margin-right: 15px;
    font-size: 20px;
  }
  li::marker {
    display: none;
  }
}
