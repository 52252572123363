@import "@root/theme/colors.scss";

.root {
  width: 415px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(26, 115, 169, 0.1);
  border-radius: 1.5rem;
}

.cardsStatisticsWrapper {
  margin: 1.25rem 0 1rem 0;
  height: 50px;
}

.statisticCard {
  width: 100%;
  height: 100%;
  background-color: $primary05Color;
  border-radius: 0.5rem;
  padding: 0.75rem;
  flex-shrink: 1;
}

.doughnutChartContainer {
  margin-top: 50px;
}

.percentages {
  color: $primaryDarkColor;
}

.colorMark {
  width: 16px;
  aspect-ratio: 1/1;
  border-radius: 100%;
}

.noData {
  padding: 31px 0;
}
