@import "@root/theme/colors.scss";
@import "@root/theme/shadows.scss";
.root {
  text-align: center;
  min-width: 333px;

  background-color: $white100Color !important;
  padding: 3rem;
  border-radius: 1.5rem;
  box-shadow: $primaryShadowL1;
}

.signUpContainer {
  display: flex;
  gap: 0.2rem;

  & > .button {
    padding: 0;
  }
}

.root {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.termsText {
  max-width: 346px;

  & > span {
    color: $primary100Color;
    cursor: pointer;
  }
}

.authContainer {
  .submitButton {
    margin-top: 8px;
  }
}
