.root {
  & > * {
    max-width: 21.125rem;
    text-align: center;
  }
}

.backButton {
  position: fixed;
  left: 3.75rem;
  top: 6.25rem;
}
