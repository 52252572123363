@import "@root/theme/colors.scss";
.root {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 12px;

  & > * {
    flex-grow: 0;
  }
}

.input {
  display: flex;
  align-items: center;
  gap: 10px;
  & > * {
    white-space: nowrap;
  }

  & > *:last-child {
    flex: 1 1;
  }
}

.control {
  display: flex;
  gap: 12px;
}

.periodLabel {
  text-align: center;
  min-width: 12ch;
}
.helperText {
  text-align: center;
  margin-top: 4px;
  margin-left: 9ch;
}
