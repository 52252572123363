@import "@root/theme/colors.scss";

.root {
  margin-top: 74px;
  cursor: pointer;
}

.wrapper {
  display: flex;
  margin-top: 20px;
  column-gap: 12px;
  align-items: stretch;
  min-height: 360px;
  flex-wrap: wrap;
}

.modalRoot {
  position: relative;
  padding: 1.5rem;
  box-sizing: border-box;
}

.tableContainer {
  flex: 0 1 100%;
  max-width: 32.5rem;
  width: 100%;
  height: 100%;
  max-height: 19.5rem;
}

.chartContainer {
  flex: 0 1 100%;
  box-shadow: unset !important;
  background: unset !important;
}

.closeIcon {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.horizontalDivider {
  flex: 0 0 auto;
  height: 312px;
  width: 1px;
  background-color: $black20Color;
}
