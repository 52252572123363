@import "@root/theme/colors.scss";

.formContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 3rem 10.5rem;
}

.signUpContainer {
  display: flex;
  gap: 0.2rem;

  & > .button {
    padding: 0;
  }
}

.termsText {
  max-width: 346px;
  text-align: center;
  & > span {
    color: $primary100Color;
    cursor: pointer;
  }
}

.authContainer {
  .submitButton {
    margin-top: 8px;
  }
}

.inputContainer {
  & > div {
    background: transparent;
  }
}
