@import "@root/theme/mixins.scss";
@import "@root/theme/colors.scss";
@import "@root/theme/shadows.scss";

.root {
  height: var(--global-total-height, 100vh);
}

.title {
  letter-spacing: 2px;
  @include respond-to(mobile) {
    padding: 28px 0 0 28px;
  }
}

.wrapper {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
}

.root.color-default {
  background: #f6f8ff;
  @include respond-to(mobile) {
    background: #ffffff;
  }
}

.root.color-primary {
  background: linear-gradient(
    180deg,
    $primaryLightColor 0%,
    $primaryDarkColor 100%
  );
  color: $lightTextColor;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 100%;
  margin-inline: auto;
  height: 100%;
  align-items: center;
}

.header {
  display: flex;
  width: 100%;
  height: 75px;
  justify-content: space-between;
  align-items: center;
  background-color: $white100Color;
  box-shadow: $primaryShadowL1;
  cursor: default;
  user-select: none;
}

.backButton {
  position: fixed;
  top: 8.125rem;
  left: 3.75rem;
}

.before,
.after {
  flex: 1 1 100%;
  display: flex;
  align-items: center;
}

.before {
  justify-content: flex-start;
}

.after {
  justify-content: flex-end;
}
