@import "@root/theme/colors.scss";
.root {
  width: 25rem;

  display: flex;
  flex-direction: column;

  * {
    box-sizing: border-box;
  }
  z-index: 10;

  * {
    z-index: 300 !important;
    top: auto !important;
    left: auto !important;
  }
}

.header,
.mainPadding,
.mainContainer {
  padding: 2rem;
}

.mainContainer {
  flex-grow: 1;
}

.footer {
  flex: none;
  padding: 1.5rem;
  box-shadow: 0px -4px 12px rgba(233, 236, 246, 0.8);
}

.header {
  flex: none;
  padding-bottom: 0 !important;
}

.metricIcon {
  color: $grayTextColor;
  cursor: pointer;
}

.metricItemContainer {
  -webkit-user-drag: element;
}
