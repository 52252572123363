@import "@root/theme/colors.scss";
@import "@root/theme/shadows.scss";

.wrapper {
  height: calc(100vh - 180px);
  width: 100%;
}

.root {
  text-align: center;
  box-shadow: $primaryShadowL1;
  background-color: $white100Color !important;
  border-radius: 1.5rem;
  padding: 3rem;
}
