.root {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(26, 115, 169, 0.1);
  border-radius: 24px;
  height: 291px;
  box-sizing: border-box;
  width: 100%;
}

.bar {
  flex: none;
  width: 100%;
}
