@import "@root/theme/utils.scss";

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.25rem;
  border-radius: 1.5rem;
  margin-top: $p20;
  column-gap: $p30;
}

.filterButton {
  position: relative;
}

.metricsMenu {
  position: absolute;
  z-index: 2;
}

.goalWidgetContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}
