@import "@root/theme/colors.scss";

$item-height: 46px;

.root {
  width: 100%;
  padding: 1rem 0;
}

.headerText {
  padding-bottom: 1rem;
}

.tableContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 1.5rem;
  background-color: #fff;
}

.buttonsContainer {
  flex: none;
}

.mainGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 13.75rem 1fr 6.25rem 10rem;
}

.tagContainer {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-color: $primary10Color;
  cursor: pointer;
}

.metricsColumnContianer {
  & > .item {
    height: $item-height;
    box-sizing: border-box;
    padding: 0.75rem;
    border-right: 1px solid $black20Color;
    border-top: 1px solid $black20Color;
  }
}

.dateContainer,
.metricsGridContainer {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  &::-webkit-scrollbar {
    display: none;
  }
}

.dateContainer {
  width: 100%;
  overflow-y: auto;
}
.dataItem {
  flex: none;
  padding-left: 0.6rem;
  box-sizing: border-box;
  width: 6.5rem;
}

.channelItem {
  width: 100%;
  border: 1px solid $primary20Color;
  border-radius: 0.5rem;
}

.metricsGridContainer {
  width: 100%;
  display: grid;
  overflow-y: auto;
}

.metricItem {
  box-sizing: border-box;
  padding: 0.75rem;
  height: $item-height;
  border-top: 1px solid $black20Color;
}

.staticColumnContainer {
  width: 100%;
  background-color: $secondary20Color;
}
.staticMetrics {
  border-left: 1px solid $black20Color;
}

.summaryColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.trendItem {
  width: fit-content;
  box-sizing: border-box;
  padding: 1px 4px;
  border-radius: 0.75rem;
}
